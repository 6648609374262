import React from 'react';

class Schedule extends React.Component {
  render() {
    const content = '<!-- Start Square Appointments Embed Code --><script src=\'https://square.site/appointments/buyer/widget/wsadz8jubzwx9l/NQPHSP3149GH8.js\'></script><!-- End Square Appointments EmbedCode -->'
    return (
      content
    );
  }
}

export default Schedule;
